import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useAddSieveSet,
  useCleanSieveSetMutation as useWashSieveSet,
  useGetSieveSets,
  useAddOrUpdateSieve,
  useDeleteSieveSet,
  useDeleteSieve,
} from '../../queries/QualityControlQueries'
import { useNavigate } from 'react-router-dom'
import { EntityTableContainer } from '../../utilities/EntityTableContainer'
import { useGetSites } from '../../queries/AdminQueries'
import {
  Sieve,
  SieveSet,
  Site,
  WashLogEntry,
} from '../../types/QualityControlModels'
import CloseIcon from '@mui/icons-material/Close'
import { RelativeTime } from '../../utilities/DateUtilities'
import { SieveSetWashLog } from '../qualityControl/subComponents/SieveSetWashLog'
import BookRoundedIcon from '@mui/icons-material/BookRounded'
import { EntityTableToolbarButton } from '../general/EntityTableToolbarButton'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { EditRow } from '../../utilities/ReactTableComponents'
import {
  Add,
  Block,
  Cancel,
  CheckCircle,
  ChevronRight,
  Delete,
  Edit,
  ExpandMore,
  Save,
  Warning,
} from '@mui/icons-material'
import { CheckBoxSelect } from '../general/CheckBoxSelect'
import dayjs from 'dayjs'
import React from 'react'
import { useUserInfo } from '../../hooks/general/useUserInfo'

export const ManageSieveSets = () => {
  const { data: sieveSets, refetch: refetchSieveSets } = useGetSieveSets()
  const { userInfo } = useUserInfo()
  const { mutate: addSieveSet, isLoading: isAddingSieveSet } = useAddSieveSet()
  const { data: sites, isLoading: isSitesLoading } = useGetSites()
  const { mutate: washSieveSet } = useWashSieveSet()
  const [addRow, setAddRow] = useState<SieveSet | null>(null)
  const [editRow, setEditRow] = useState(null)
  const [showSievesForSieveSet, setShowSievesForSieveSet] = useState(null)
  const [showWashLog, setShowWashLog] = useState(false)
  const [showConfirmWash, setShowConfirmWash] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const columnHelper = createColumnHelper<SieveSet>()
  const [columnVisibility] = useState({ id: false })
  const [deleteSieveSet, setDeleteSieveSet] = useState(null)

  const handleWashSieveSet = useCallback(
    (row) => {
      washSieveSet(
        {
          sieveSetId: row.id,
          user: { userId: userInfo?.id, username: userInfo?.displayName },
          timestampUtc: new Date().toISOString(),
        },
        {
          onSuccess: () => refetchSieveSets(),
          onSettled: () => setShowConfirmWash(false),
        },
      )
    },
    [refetchSieveSets, washSieveSet, userInfo],
  )

  const handleConfirmWash = useCallback((row) => {
    setSelectedRow(row)
    setShowConfirmWash(true)
  }, [])

  const handleCancelChanges = () => {
    setAddRow(null)
    setEditRow(null)
  }

  const columns = useMemo(
    () => [
      // ID Column
      columnHelper.accessor('id', {
        header: 'ID',
        cell: (info) => info.getValue(),
        size: 100, // Adjust size if needed, flex will need manual adjustments
      }),

      columnHelper.display({
        header: 'Sieves',
        cell: ({ row }) =>
          showSievesForSieveSet?.id === row.original.id ? (
            <IconButton
              title="Hide Sieves"
              onClick={() => setShowSievesForSieveSet(null)}
            >
              <ExpandMore />
            </IconButton>
          ) : (
            <IconButton
              title="Show Sieves"
              onClick={() => setShowSievesForSieveSet(row.original)}
            >
              <ChevronRight />
            </IconButton>
          ),

        size: 150,
      }),
      // Name Column
      columnHelper.accessor('name', {
        header: 'Name',
        size: 225,
        cell: (context) => {
          return context.getValue()
        },
        meta: {
          editCell: (state, setState) => (
            <TextField
              size="small"
              value={state?.name}
              onChange={(e) =>
                setState((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          ),
        },
      }),

      // Sites Column
      columnHelper.accessor('sites', {
        header: 'Sites',
        cell: (info) =>
          info.row.original.sites?.map((site) => site.name).join(' | ') || '',
        size: 350,
        meta: {
          editCell: (state, setState) => {
            return (
              <CheckBoxSelect
                data={sites}
                isLoadingData={isSitesLoading}
                selected={state?.sites}
                handleSelectionChange={(selectedSites: Site[]) => {
                  setState((prev) => ({
                    ...prev,
                    sites: [
                      ...selectedSites.map((site) => ({
                        ...site,
                        id: site.id,
                      })),
                    ],
                  }))
                }}
              />
            )
          },
        },
      }),

      // Description Column
      columnHelper.accessor('description', {
        header: 'Description',
        size: 200,
        meta: {
          editCell: (state, setState) => {
            return (
              <TextField
                size="small"
                value={state?.description}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, description: e.target.value }))
                }
              />
            )
          },
        },
      }),

      // Number of Sieves Column
      columnHelper.display({
        header: '# of Sieves',
        cell: (info) => info.row.original.sieves?.length || 0,
        size: 150,
        // isEditable: false,
      }),

      // Last Washed Column
      columnHelper.accessor('lastWashTimestamp', {
        header: 'Last Washed',
        cell: (context) => (
          <Link
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
            onClick={() => handleConfirmWash(context.row.original)}
          >
            {context.getValue() ? (
              <RelativeTime timestamp={dayjs(context.getValue()).toDate()} />
            ) : (
              'Never'
            )}
          </Link>
        ),
        size: 200,
        // isEditable: false,
      }),

      // Uses Since Wash Column
      columnHelper.display({
        header: 'Uses Since Wash',
        size: 200,
        // isEditable: false,
      }),

      // Is Camsizer Column
      columnHelper.accessor('isCamsizer', {
        header: 'Camsizer?',
        cell: (info) => (info.getValue() ? 'Yes' : 'No'),
        size: 100,
        meta: {
          editCell: (state, setState) => {
            return (
              <Checkbox
                size="small"
                checked={state?.isCamsizer}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    isCamsizer: e.target.value === 'true',
                  }))
                }
              />
            )
          },
        },
      }),

      // Is Active Column
      columnHelper.accessor('isActive', {
        header: 'Active',
        cell: (info) =>
          info.getValue() ? (
            <CheckCircle color="success" />
          ) : (
            <Block color="warning" />
          ),
        size: 100,
        meta: {
          editCell: (state, setState) => {
            return (
              <Checkbox
                size="small"
                checked={state?.isActive}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    isActive: e.target.value === 'true',
                  }))
                }
              />
            )
          },
        },
      }),

      columnHelper.display({
        header: 'Actions',
        cell: (context) => (
          <>
            <IconButton
              size="small"
              onClick={() => {
                setEditRow(context.row.original)
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setDeleteSieveSet(context.row.original)
              }}
            >
              <Delete />
            </IconButton>
          </>
        ),
        meta: {
          editCell: (state) => (
            <>
              <IconButton
                size="small"
                title="Save Changes"
                onClick={() => {
                  addSieveSet(state, { onSuccess: () => handleCancelChanges() })
                }}
              >
                {isAddingSieveSet ? <CircularProgress size={24} /> : <Save />}
              </IconButton>
              <IconButton
                size="small"
                title="Cancel Changes"
                onClick={handleCancelChanges}
              >
                <Cancel />
              </IconButton>
            </>
          ),
        },
      }),
    ],
    [
      addSieveSet,
      columnHelper,
      handleConfirmWash,
      isAddingSieveSet,
      isSitesLoading,
      showSievesForSieveSet,
      sites,
    ],
  )

  const table = useReactTable({
    columns,
    data: sieveSets,
    state: {
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {showConfirmWash && (
        <ConfirmWashDialog
          sieveSet={selectedRow}
          open={showConfirmWash}
          handleClose={() => setShowConfirmWash(false)}
          handleWashSieveSet={handleWashSieveSet}
        />
      )}
      {showWashLog && (
        <WashLogDialog
          open={showWashLog}
          handleClose={() => setShowWashLog(false)}
        />
      )}
      {deleteSieveSet && (
        <DeleteSieveSetDialog
          open={!!deleteSieveSet}
          sieveSet={deleteSieveSet}
          handleClose={() => setDeleteSieveSet(null)}
        />
      )}

      <Box
        sx={{
          height: '75vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        {sieveSets && (
          <EntityTableContainer
            title="Sieve Sets"
            table={
              <>
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                    width: '100%',
                  })}
                >
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    sx={(theme) => ({
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                    })}
                    size="small"
                    onClick={() =>
                      setAddRow({
                        id: 0,
                        name: '',
                        description: '',
                        isCamsizer: false,
                        isActive: true,
                        sites: [],
                        lastWashTimestamp: new Date(),
                        lastWashTimestampUtc: new Date().toISOString(),
                        sieves: [],
                      })
                    }
                  >
                    Add Sieve Set
                  </Button>
                  <EntityTableToolbarButton
                    key="washLog"
                    size="small"
                    startIcon={<BookRoundedIcon />}
                    onClick={() => setShowWashLog(true)}
                  >
                    Wash Log
                  </EntityTableToolbarButton>
                </Box>
                <TableContainer sx={{ height: '72vh' }}>
                  <Table stickyHeader>
                    <TableHead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header, index) => (
                            <TableCell key={index}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody>
                      {!!addRow && <EditRow table={table} rowState={addRow} />}
                      {table.getRowModel().rows.map((row) => {
                        return editRow?.id === row.original.id ? (
                          <EditRow
                            key={row.id}
                            table={table}
                            rowState={editRow}
                            rowData={row}
                          />
                        ) : (
                          <Fragment key={row.id}>
                            <TableRow>
                              {row.getVisibleCells().map((cell) => (
                                <TableCell
                                  key={cell.id}
                                  width={cell.column.getSize()}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext(),
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            {showSievesForSieveSet?.id === row.original.id &&
                              (row.original.sieves?.length ?? 0) > 0 && (
                                <TableRow>
                                  <TableCell
                                    colSpan={
                                      table.getVisibleFlatColumns().length
                                    }
                                    sx={(theme) => ({
                                      backgroundColor: theme.palette.grey[200],
                                      padding: '32px',
                                    })}
                                  >
                                    <SieveSetSievesTable
                                      sieveSet={sieveSets.find(
                                        (sieveSet) =>
                                          sieveSet.id ===
                                          showSievesForSieveSet.id,
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                          </Fragment>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            }
          />
        )}
      </Box>
    </>
  )
}

const SieveSetSievesTable = ({ sieveSet }: { sieveSet: SieveSet }) => {
  const [editSieveRow, setEditSieveRow] = useState<Sieve | null>(null)
  const [deleteSieve, setDeleteSieve] = useState(null)
  const [addSieveRow, setAddSieveRow] = useState<Sieve>(null)
  const {
    mutate: addOrUpdateSieve,
    isLoading: isLoadingUpdateSieve,
  } = useAddOrUpdateSieve()

  const columnHelper = createColumnHelper<Sieve>()

  const columns = useMemo(
    () => [
      // Size Column
      columnHelper.accessor('size', {
        header: 'Size',
        cell: (info) => info.getValue(),
        size: 150, // Equivalent to flex: 1.5
        meta: {
          editCell: (state, setState) => (
            <TextField
              size="small"
              value={state?.size}
              onChange={(e) =>
                setState((prev) => ({ ...prev, size: e.target.value }))
              }
            />
          ),
        },
      }),

      // Serial Column
      columnHelper.accessor('serial', {
        header: 'Serial',
        cell: (info) => info.getValue(),
        size: 200, // Equivalent to flex: 2
        meta: {
          editCell: (state, setState) => (
            <TextField
              size="small"
              value={state?.serial}
              onChange={(e) =>
                setState((prev) => ({ ...prev, serial: e.target.value }))
              }
            />
          ),
        },
      }),

      // Initial Weight Column
      columnHelper.accessor('initialWeight', {
        header: 'Initial Weight',
        cell: (info) => info.getValue(),
        size: 200, // Equivalent to flex: 2
        meta: {
          editCell: (state, setState) => (
            <TextField
              size="small"
              type="number"
              value={state?.initialWeight}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  initialWeight: Number(e.target.value),
                }))
              }
            />
          ),
        },
      }),

      // Current Weight Column
      columnHelper.accessor('currentWeight', {
        header: 'Current Weight',
        cell: (info) => info.getValue(),
        size: 200, // Equivalent to flex: 2
      }),

      // Total Uses Column
      columnHelper.accessor('totalUses', {
        header: 'Total Uses',
        cell: (info) => info.getValue(),
        size: 175, // Equivalent to flex: 1.75
      }),

      // Pan Column
      columnHelper.accessor('isPan', {
        header: 'Pan',
        cell: (info) => (info.getValue() ? 'Yes' : 'No'),
        size: 150, // Equivalent to flex: 1.5
        meta: {
          editCell: (state, setState) => {
            return (
              <Checkbox
                size="small"
                checked={state?.isPan}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, isPan: e.target.checked }))
                }
              />
            )
          },
        },
      }),

      // Created Timestamp Column
      columnHelper.accessor('createdTimestampUtc', {
        header: 'Created',
        cell: (info) => (
          <RelativeTime timestamp={dayjs.utc(info.getValue()).toDate()} />
        ),
        size: 300, // Equivalent to flex: 3
      }),

      // Display Order Column
      columnHelper.accessor('displayOrder', {
        header: 'Order',
        cell: (info) => info.getValue(),
        size: 100, // Equivalent to flex: 1
        meta: {
          editCell: (state, setState) => (
            <TextField
              size="small"
              value={state?.displayOrder}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  displayOrder: Number(e.target.value),
                }))
              }
            />
          ),
        },
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (context) => (
          <>
            <IconButton
              size="small"
              onClick={() => {
                setEditSieveRow(context.row.original)
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setDeleteSieve(context.row.original)
              }}
            >
              <Delete />
            </IconButton>
          </>
        ),
        meta: {
          editCell: (state) => (
            <>
              <IconButton
                size="small"
                title="Save Changes"
                onClick={() => {
                  addOrUpdateSieve(
                    { ...state },
                    {
                      onSuccess: () => {
                        setEditSieveRow(null)
                        setAddSieveRow(null)
                      },
                    },
                  )
                }}
              >
                {isLoadingUpdateSieve ? (
                  <CircularProgress size={24} />
                ) : (
                  <Save />
                )}
              </IconButton>
              <IconButton
                size="small"
                title="Cancel Changes"
                onClick={() => {
                  setEditSieveRow(null)
                  setAddSieveRow(null)
                }}
              >
                <Cancel />
              </IconButton>
            </>
          ),
        },
      }),
    ],
    [columnHelper, isLoadingUpdateSieve, addOrUpdateSieve],
  )
  const [sieveColumnVisibility] = useState({ serial: false })

  const sievesTable = useReactTable({
    columns,
    data: sieveSet?.sieves ?? [],
    state: { columnVisibility: sieveColumnVisibility },
    getCoreRowModel: getCoreRowModel(),
  })

  const { rows } = sievesTable.getRowModel()
  const sortedRows = useMemo(
    () =>
      rows.sort((a, b) =>
        b.original.isPan
          ? -1
          : Number(a.original.size) - Number(b.original.size),
      ),
    [rows],
  )
  return (
    <>
      {deleteSieve && (
        <DeleteSieveDialog
          open={!!deleteSieve}
          sieve={deleteSieve}
          handleClose={() => setDeleteSieve(null)}
        />
      )}
      <TableContainer>
        <Paper
          sx={(theme) => ({
            margin: 'auto',
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
          })}
          variant="outlined"
        >
          <Grid container spacing={2} p={2}>
            <Grid item md={8}>
              <Typography variant="h5">
                <Button
                  startIcon={<Add />}
                  onClick={() =>
                    setAddSieveRow({
                      currentWeight: 0,
                      sieveSetId: sieveSet.id,
                      size: '',
                      serial: '',
                      totalUses: 0,
                      lastWashTimestamp: null,
                      unitOfMeasure: 'grams',
                      initialWeight: 0,
                      isPan: false,
                      createdTimestampUtc: new Date().toISOString(),
                      displayOrder: sievesTable.getRowCount() + 2,
                      isNew: true,
                    })
                  }
                >
                  Add Sieve
                </Button>
              </Typography>
            </Grid>
            <Grid></Grid>
          </Grid>
          <Table>
            <TableHead>
              {sievesTable.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody>
              {!!addSieveRow && (
                <EditRow table={sievesTable} rowState={addSieveRow} />
              )}
              {sortedRows.map((row, rowIndex) =>
                editSieveRow?.id === row.original.id ? (
                  <EditRow
                    key={row.id}
                    table={sievesTable}
                    rowState={editSieveRow}
                    rowData={row}
                  />
                ) : (
                  <Fragment key={row.id}>
                    <TableRow
                      sx={(theme) => ({
                        backgroundColor:
                          rowIndex % 2 === 0
                            ? theme.palette.grey[100]
                            : 'inherit',
                      })}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          size="small"
                          key={cell.id}
                          width={cell.column.getSize()}
                          sx={{
                            borderBottom: rows.length === rowIndex + 1 && 0,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </Fragment>
                ),
              )}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </>
  )
}

const WashLogDialog = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
    <DialogTitle>
      Wash Log
      <IconButton
        aria-label="close-modal"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <SieveSetWashLog />
    </DialogContent>
  </Dialog>
)

const ConfirmWashDialog = ({
  open,
  handleClose,
  sieveSet,
  handleWashSieveSet,
}: {
  open: boolean
  handleClose: () => void
  sieveSet: SieveSet
  handleWashSieveSet: (sieveSet: SieveSet) => void
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Confirm Wash
        <IconButton
          aria-label="close-modal"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Sites</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{sieveSet.id}</TableCell>
              <TableCell>
                {sieveSet.sites.map((site) => site.name).join(' | ') || ''}
              </TableCell>
              <TableCell>{sieveSet.name}</TableCell>
              <TableCell>{sieveSet.description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        Mark as cleaned?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleWashSieveSet(sieveSet)}>Confirm</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

const DeleteSieveSetDialog = ({
  open,
  handleClose,
  sieveSet,
}: {
  open: boolean
  handleClose: () => void
  sieveSet: SieveSet
}) => {
  const { mutate: deleteSieveSet } = useDeleteSieveSet()
  const handleDeleteSieveSet = () => {
    deleteSieveSet(sieveSet.id, {
      onSuccess: () => {
        handleClose()
      },
    })
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Delete Sieve Set & Sieves
        <IconButton
          aria-label="close-modal"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>Sites</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
          </TableHead>
          <TableBody>
            <TableCell>{sieveSet.id}</TableCell>
            <TableCell>
              {sieveSet.sites.map((site) => site.name).join(' | ') || ''}
            </TableCell>
            <TableCell>{sieveSet.name}</TableCell>
            <TableCell>{sieveSet.description}</TableCell>
          </TableBody>
        </Table>
        Are you sure you want to delete this sieve set and all of it&apos;s
        sieves?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDeleteSieveSet}
          startIcon={<Warning />}
          color="warning"
        >
          Confirm
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

const DeleteSieveDialog = ({
  open,
  handleClose,
  sieve,
}: {
  open: boolean
  handleClose: () => void
  sieve: Sieve
}) => {
  const { mutate: deleteSieve } = useDeleteSieve(sieve.sieveSetId)
  const handleDeleteSieve = () => {
    deleteSieve(sieve.id, { onSuccess: () => handleClose() })
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Delete Sieve
        <IconButton
          aria-label="close-modal"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Serial</TableCell>
          </TableHead>
          <TableBody>
            <TableCell>{sieve.id}</TableCell>
            <TableCell>{sieve.size}</TableCell>
            <TableCell>{sieve.serial}</TableCell>
          </TableBody>
        </Table>
        Are you sure you want to delete this sieve?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDeleteSieve}
          startIcon={<Warning />}
          color="warning"
        >
          Confirm
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
