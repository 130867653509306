export function sortSieveStatistics(data) {
    if (!data) return [];
    return data.sort((a, b) => {
        // Check if either sieveSize is "PAN" and handle accordingly
        if (a.sieveSize === "PAN") return 1; 
        if (b.sieveSize === "PAN") return -1; 

        // Otherwise, compare sieve sizes as numbers
        const sizeA = parseInt(a.sieveSize);
        const sizeB = parseInt(b.sieveSize);

        // If both are numbers, sort numerically
        if (!isNaN(sizeA) && !isNaN(sizeB)) {
            return sizeA - sizeB;
        }

        // In case of any non-numeric values, sort alphabetically
        return a.sieveSize.localeCompare(b.sieveSize);
    });
}