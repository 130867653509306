import { Card, CardContent, CardHeader, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PaperItem } from "../../utilities/PaperItem";

export const Admin = () => {
    
    return (
        <Grid container>
            <PaperItem>
                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="h4">Admin</Typography>
                </Grid>
                <Grid container></Grid>
                <Stack spacing={2}>
                    <Typography component={Link} to="/admin/sites" sx={{ textDecoration: "none" }}>
                        Sites
                    </Typography>

                    <Typography component={Link} to="/admin/plants" sx={{ textDecoration: "none" }}>
                        Plants
                    </Typography>

                    <Typography component={Link} to="/admin/locations" sx={{ textDecoration: "none" }}>
                        Locations
                    </Typography>

                    <Typography component={Link} to="/admin/sieve-sets" sx={{ textDecoration: "none" }}>
                        Sieves
                    </Typography>

                    <Typography component={Link} to="/admin/test-types" sx={{ textDecoration: "none" }}>
                        Test Types
                    </Typography>

                    <Typography component={Link} to="/admin/composite-types" sx={{ textDecoration: "none" }}>
                        Composite Types
                    </Typography>

                    <Typography component={Link} to="/admin/sieve-groups" sx={{ textDecoration: "none" }}>
                        Sieve Groups
                    </Typography>

                    <Typography component={Link} to="/admin/site-sieve-group-map" sx={{ textDecoration: "none" }}>
                        Site - Sieve Group mappings
                    </Typography>
                </Stack>
            </PaperItem>
        </Grid>
    );
};
