import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useUserStore } from "../../store/UserStore";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { fetchUserRoles } from "../../queries/AdminQueries";

export const ROLES = {
    READER: "Reader",
    WRITER: "Writer",
    ADMIN: "Admin",
};

export const PERMISSIONS = {
    VIEW: [ROLES.READER, ROLES.WRITER, ROLES.ADMIN],
    EDIT: [ROLES.WRITER, ROLES.ADMIN],
    MANAGE: [ROLES.ADMIN],
};


export const useUserRoles = () => {
    const { accounts, instance } = useMsal();
    const setUserRoles = useUserStore((state) => state.setUserRoles);

    useEffect(() => {
        const fetchAndSetRoles = async () => {
            if (accounts?.[0]) {
                const roles = await fetchUserRoles(instance, accounts[0], ["User.Read"]);
                setUserRoles(roles); // Update Zustand store
            }
        };

        fetchAndSetRoles();
    }, [accounts, instance, setUserRoles]);
};
