import { TableCell, TextField, styled } from "@mui/material";

export const GrayTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
}));

export const VividTextField = styled(TextField)(() => ({
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
        WebkitTextFillColor: "rgba(83, 83, 83, 1)",
    },
    "& .MuiInputBase-input": {
        fontWeight: 400,
    },
    "& .MuiInputLabel-root": {
        WebkitTextFillColor: "rgba(83, 83, 83, 1)",
        fontWeight: 400,
    },
}));