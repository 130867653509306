import React from 'react';
import { Admin } from "./components/admin/Admin";
import { ManageCompositeTypes } from "./components/admin/ManageCompositeTypes";
import { DocumentManager } from "./components/admin/ManageDocuments";
import { ManageLocations } from "./components/admin/ManageLocations";
import { ManagePlants } from "./components/admin/ManagePlants";
import { ManageSiteSieveGroupMaps } from "./components/admin/ManageSiteSieveGroupMap";
import { ManageSieveSetSieves } from "./components/admin/ManageSieveSetSieves";
import { ManageSieveSets } from "./components/admin/ManageSieveSets";
import { ManageSites } from "./components/admin/ManageSites";
import { ManageTestTypes } from "./components/admin/ManageTestTypes";
import { Home } from "./components/general/Home";
import { Profile } from "./components/general/Profile";
import { QualityControlAddSampleGroup } from "./components/qualityControl/QualityControlAddSampleGroup";
import QualityControlHome from "./components/qualityControl/QualityControlHome";
import { QualityControlOverview } from "./components/qualityControl/QualityControlOverview";
import { QualityControlSample } from "./components/qualityControl/QualityControlSample";
import { QualityControlSampleDatabase } from "./components/qualityControl/QualityControlSampleDatabase";
import { ManageSieveGroups } from "./components/admin/ManageSieveGroups";
import { PERMISSIONS } from './hooks/auth/useUserRoles';

export const routes = [
    {
        path: "/",
        breadcrumb: "Home",
        element: <Home />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/profile",
        breadcrumb: "Profile",
        element: <Profile />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/admin",
        breadcrumb: "Admin",
        element: <Admin />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/sites",
        breadcrumb: "Sites",
        element: <ManageSites />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/plants",
        breadcrumb: "Plants",
        element: <ManagePlants />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/locations",
        breadcrumb: "Locations",
        element: <ManageLocations />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/sieve-sets",
        breadcrumb: "Sieve Sets",
        element: <ManageSieveSets />,
        permissions: PERMISSIONS.EDIT
    },
    {
        path: "/admin/composite-types",
        breadcrumb: "Composite Types",
        element: <ManageCompositeTypes />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/test-types",
        breadcrumb: "Test Types",
        element: <ManageTestTypes />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/admin/sieve-sets/:sieveSetId",
        breadcrumb: "Sieve Set Sieves",
        element: <ManageSieveSetSieves />,
        permissions: PERMISSIONS.MANAGE
    },
    {
        path: "/qc",
        breadcrumb: null,
        element: <Home />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/qc/:siteCode",
        breadcrumb: (state) => <>{state.match.params.siteCode}</>,
        element: <QualityControlHome />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/qc/:siteCode/documents",
        breadcrumb: "Documents",
        element: <DocumentManager />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/qc/:siteCode/overview",
        breadcrumb: "QC Overview",
        element: <QualityControlOverview />,
        permissions: PERMISSIONS.VIEW

    },
    {
        path: "/qc/:siteCode/samples",
        breadcrumb: () => <>Samples</>,
        element: <QualityControlSampleDatabase />,
        permissions: PERMISSIONS.VIEW

    },
    {
        path: "/qc/:siteCode/samples/:sampleId/:mode",
        breadcrumb: null,
        element: <QualityControlSample />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/qc/:siteCode/samples/:sampleId/",
        element: <QualityControlSample />,
        permissions: PERMISSIONS.VIEW
    },
    {
        path: "/qc/:siteCode/add-sample",
        breadcrumb: "Add Sample",
        element: <QualityControlAddSampleGroup />,
        permissions: PERMISSIONS.EDIT

    },
    {
        path: "/admin/site-sieve-group-map",
        breadcrumb: "Site Sieve Group Map",
        element: <ManageSiteSieveGroupMaps />,
        permissions: PERMISSIONS.MANAGE

    },
    {
        path: "/admin/sieve-groups",
        breadcrumb: "Sieve Groups",
        element: <ManageSieveGroups />,
        permissions: PERMISSIONS.MANAGE
    },
];
