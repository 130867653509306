import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { useUserHasPermission } from "../../hooks/auth/useUserHasPermission";

export const PrivateRoute = ({ permissions }) => {
    const hasPermission = useUserHasPermission(permissions);
    return hasPermission ? (
        <Outlet />
    ) : (
        <Grid container>
            <Grid item xs={12}>
                <h3>Not Authorized</h3>
            </Grid>
        </Grid>
    );
};
