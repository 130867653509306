import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    TextField,
    InputAdornment,
    Chip,
    Typography,
    Stack,
    Divider,
    Card,
    CardContent,
} from "@mui/material";
import { useState, useEffect } from "react";
import { CrushAnalysis, Sample } from "../../../types/QualityControlModels";
import { useKValuePercentCalculation, useKValuePanAveragePercentCalculation, useIsCrushTestPassCheck } from "./Calculations";
import { isEqual } from "lodash";

export const SampleCrushAnalysis = ({
    sampleUpdate,
    setSampleUpdate,
    showTitle = true,
    isReadOnly,
    disabled,
}: {
    sampleUpdate: Sample;
    setSampleUpdate: (value: any) => void;
    showTitle: boolean;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const crushTests = ["4K", "5K", "6K", "7K", "8K", "9K", "10K", "11K", "12K", "13K", "14K", "15K", "16K", "17K"];
    const [crushAnalyses, setCrushAnalyses] = useState<CrushAnalysis[]>(() => {
        const { crushAnalyses } = sampleUpdate;

        return crushTests.map((crushTest, index) => ({
            name: crushTest,
            ...crushAnalyses?.find((crushAnalysis) => crushAnalysis.name === crushTest),
        }));
    });

    const [selectedCrushTestIndex, setSelectedCrushTestIndex] = useState(0);

    const calculateKValue = useKValuePercentCalculation();
    const crushAnalysisPanAveragePercent = useKValuePanAveragePercentCalculation();
    const isCrushTestPassCheck = useIsCrushTestPassCheck();

    const handleSelectedCrushAnalysisChange = (event) => {
        setSelectedCrushTestIndex(event.target.value);
    };

    const handleResetCrushAnalysis = (crushAnalysisIndex) => {
        setCrushAnalyses((prevState: CrushAnalysis[]) => {
            const crushAnalysesCopy = [...prevState];

            crushAnalysesCopy[crushAnalysisIndex] = {
                name: crushTests[crushAnalysisIndex],
                pan1Value: null,
                pan2Value: null,
                pan3Value: null,
                isPass: null,
                average: null,
            };
            return crushAnalysesCopy;
        });
    };

    const handlePan1Change = (event) => {
        const pan1Value = event.target.value;
        setCrushAnalyses((prevState: CrushAnalysis[]) => {
            const updatedAnalyses = [...prevState]; // create a copy of the array

            updatedAnalyses[selectedCrushTestIndex] = {
                ...updatedAnalyses[selectedCrushTestIndex],
                pan1Value,
            };

            return updatedAnalyses;
        });
    };

    const handlePan2Change = (event) => {
        const pan2Value = event.target.value;
        setCrushAnalyses((prevState: CrushAnalysis[]) => {
            const updatedAnalyses = [...prevState]; // create a copy of the array

            updatedAnalyses[selectedCrushTestIndex] = {
                ...updatedAnalyses[selectedCrushTestIndex],
                pan2Value,
            };

            return updatedAnalyses;
        });
    };

    const handlePan3Change = (event) => {
        const pan3Value = event.target.value;
        setCrushAnalyses((prevState: CrushAnalysis[]) => {
            const updatedAnalyses = [...prevState]; // create a copy of the array

            updatedAnalyses[selectedCrushTestIndex] = {
                ...updatedAnalyses[selectedCrushTestIndex],
                pan3Value,
            };

            return updatedAnalyses;
        });
    };

    useEffect(() => {
        if (!!crushAnalyses[selectedCrushTestIndex]) {
            const average = crushAnalysisPanAveragePercent({
                kValuePan1Percent: calculateKValue({
                    pan: Number(crushAnalyses[selectedCrushTestIndex]?.pan1Value),
                }),
                kValuePan2Percent: calculateKValue({
                    pan: Number(crushAnalyses[selectedCrushTestIndex]?.pan2Value),
                }),
                kValuePan3Percent: calculateKValue({
                    pan: Number(crushAnalyses[selectedCrushTestIndex]?.pan3Value),
                }),
            });
            const isPass = isCrushTestPassCheck({
                averageKPanPercent: average,
            });

            if (
                crushAnalyses[selectedCrushTestIndex].isPass !== isPass ||
                crushAnalyses[selectedCrushTestIndex].average !== average
            )
                setCrushAnalyses((prevState: CrushAnalysis[]) => {
                    const updatedAnalyses = [...prevState]; // create a copy of the array

                    updatedAnalyses[selectedCrushTestIndex] = {
                        ...updatedAnalyses[selectedCrushTestIndex],
                        average,
                        isPass,
                    };

                    return updatedAnalyses;
                });
        }
    }, [isCrushTestPassCheck, crushAnalysisPanAveragePercent, calculateKValue, selectedCrushTestIndex, crushAnalyses]);

    useEffect(() => {
        if (!isEqual(sampleUpdate.crushAnalyses, crushAnalyses)) {
            setSampleUpdate((prevState: Sample) => {
                const updatedCrushAnalyses = crushAnalyses.map((analysis) => ({
                    ...analysis,
                    pan1Value: Number(analysis.pan1Value),
                    pan2Value: Number(analysis.pan2Value),
                    pan3Value: Number(analysis.pan3Value),
                }));

                return {
                    ...prevState,
                    crushAnalyses: updatedCrushAnalyses,
                };
            });
        }
    }, [crushAnalyses, sampleUpdate?.crushAnalyses, setSampleUpdate]);

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[300]}` })}
                    paddingBottom={2}
                >
                    {showTitle && (
                        <Grid item xs={12}>
                            <Typography variant="h6">Crush Analysis</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">Test Input</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="kvalue-select-label">K Value</InputLabel>
                            <Select
                                id="kvalue-select"
                                labelId="kvalue-select-label"
                                label="K Value"
                                value={selectedCrushTestIndex ?? ""}
                                onChange={handleSelectedCrushAnalysisChange}
                                disabled={isReadOnly || disabled}
                                sx={{
                                    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
                                        WebkitTextFillColor: "rgba(83, 83, 83, 1)",
                                    },
                                    "& .MuiInputBase-input": {
                                        fontWeight: 400,
                                    },
                                    "& .MuiInputLabel-root": {
                                        WebkitTextFillColor: "rgba(83, 83, 83, 1)",
                                        fontWeight: 400,
                                    },
                                }}
                                size="small"
                            >
                                {crushTests.map((crushTest, index) => {
                                    return (
                                        <MenuItem key={index} value={index}>
                                            {crushTest}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Pan 1"
                            onChange={handlePan1Change}
                            value={crushAnalyses[selectedCrushTestIndex]?.pan1Value ?? ""}
                            helperText={`${calculateKValue({
                                pan: crushAnalyses[selectedCrushTestIndex]?.pan1Value ?? 0,
                            })}%`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Pan 2"
                            onChange={handlePan2Change}
                            value={crushAnalyses[selectedCrushTestIndex]?.pan2Value ?? ""}
                            helperText={`${calculateKValue({
                                pan: crushAnalyses[selectedCrushTestIndex]?.pan2Value ?? 0,
                            })}%`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Pan 3"
                            onChange={handlePan3Change}
                            value={crushAnalyses[selectedCrushTestIndex]?.pan3Value ?? ""}
                            helperText={`${calculateKValue({
                                pan: crushAnalyses[selectedCrushTestIndex]?.pan3Value ?? 0,
                            })}%`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {crushAnalyses?.length > 0 &&
                                crushAnalyses?.map((crushAnalysis, index) => {
                                    if (Number(crushAnalysis?.average) > 0) {
                                        return (
                                            <Chip
                                                key={index}
                                                label={
                                                    <Stack
                                                        direction="row"
                                                        divider={<Divider orientation="vertical" flexItem />}
                                                        spacing={2}
                                                    >
                                                        <Typography variant="button">{crushAnalysis.name}</Typography>

                                                        <Typography variant="button">{crushAnalysis.average}%</Typography>
                                                    </Stack>
                                                }
                                                clickable
                                                onClick={() => setSelectedCrushTestIndex(index)}
                                                onDelete={
                                                    !isReadOnly && !disabled
                                                        ? () => handleResetCrushAnalysis(index)
                                                        : undefined
                                                }
                                                color={crushAnalysis?.isPass ? "default" : "error"}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
