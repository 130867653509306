import { AppBar, Toolbar, Button, Container, Menu, MenuItem, styled, Stack, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NestedDropdown } from "mui-nested-menu";
import { useGetSites } from "../../queries/AdminQueries";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/AuthConfig";
import { useUserHasPermission } from "../../hooks/auth/useUserHasPermission";
import { PERMISSIONS } from "../../hooks/auth/useUserRoles";

export const Navigation = () => {
    const isAuthenticated = useIsAuthenticated();
    const userCanManage = useUserHasPermission(PERMISSIONS.MANAGE);
    const BRAND_IMAGE_SCALE = 0.035;
    return (
        <AppBar position="static" sx={(theme) => ({ backgroundColor: theme.palette.appbar.main })}>
            <Container maxWidth="xl" disableGutters>
                <Toolbar>
                    <img
                        src="/vista-logo-large.png"
                        width={3413 * BRAND_IMAGE_SCALE}
                        height={1250 * BRAND_IMAGE_SCALE}
                        className="d-inline-block align-top"
                        alt="Vista Logo Blue"
                    />

                    {isAuthenticated ? (
                        <Container>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Stack flexDirection="row" justifyContent="center">
                                        <Link to="/" style={{ textDecoration: "none", marginRight: "10px" }}>
                                            <AppBarItem>Home</AppBarItem>
                                        </Link>
                                        <QualityControlDropdown />
                                        {userCanManage && <Link to="/admin" style={{ textDecoration: "none", marginLeft: "10px" }}>
                                            <AppBarItem>Admin</AppBarItem>
                                        </Link>}
                                    </Stack>
                                </Grid>
                                <Grid container item xs={2} justifyContent="flex-end">
                                    <ProfileDropdown />
                                </Grid>
                            </Grid>
                        </Container>
                    ) : (
                        <LoginButton />
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const AppBarItem = styled(Button)(({ theme }) => ({
    color: theme.palette.appbar.contrastText,
}));

const LoginButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    };

    return (
        <Button color="inherit" onClick={handleLogin}>
            Log In
        </Button>
    );
};

const ProfileDropdown = () => {
    const { accounts, instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        instance.logout().catch((e) => {
            console.error(e);
        });
    };

    return (
        <>
            <Button color="inherit" onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                {accounts[0].username}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "profile-nav-dropdown" }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to="/profile" style={{ textDecoration: "none", color: "inherit" }}>
                        View Profile
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

const QualityControlDropdown = () => {
    const userCanManage = useUserHasPermission(PERMISSIONS.MANAGE);
    const userCanEdit = useUserHasPermission(PERMISSIONS.EDIT);
    const navigate = useNavigate();
    const { data: sites, isLoading } = useGetSites();
    const menuItems = useMemo(
        () => ({
            label: "Quality Control",
            items: sites?.filter((site) => site.isActive)?.map((site) => ({
                label: site.name,
                items: [
                    { label: "Overview", callback: () => navigate(`/qc/${site.code}/overview/`) },
                    { label: "Sample Database", callback: () => navigate(`/qc/${site.code}/samples/`) },
                    userCanEdit ? {
                        label: "Edit Sample",
                        callback: () => navigate(`/qc/${site.code}/samples?statuses=0&statuses=1`),
                    } : undefined,
                    { label: "Add Sample Group", callback: () => navigate(`/qc/${site.code}/add-sample`) },
                    userCanManage ? { label: "Sieve Management", callback: () => navigate(`/admin/sieve-sets`) } : undefined,
                    { label: "Documents", callback: () => navigate(`/qc/${site.code}/documents`) },
                ].filter((item) => item !== undefined),
            })),
        }),
        [navigate, sites]
    );
    return <NestedDropdown menuItemsData={menuItems} ButtonProps={{ color: "inherit" }} />;
};