import { Box, Card, CardContent, Grid, InputAdornment, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSiltPercentCalculation } from "./Calculations";

import { Sample } from "../../../types/QualityControlModels";
import { VividTextField } from "./styled";

export const SampleCharacteristics = ({
    sample,
    setSample,
    showTitle = true,
    isReadOnly,
    disabled,
}: {
    sample: Sample;
    setSample: (sample: any) => void;
    showTitle: boolean;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const [characteristics, setCharacteristics] = useState({
        turbidity: sample.turbidity ?? 0,
        roundness: sample.roundness ?? 0,
        sphericity: sample.sphericity ?? 0,
        sandHeight: sample.sandHeight ?? 0,
        siltHeight: sample.siltHeight ?? 0,
        acidSolubility: sample.acidSolubility ?? 0,
        pitId: sample.pitId ?? "",
        block: sample.block ?? "",
        bench: sample.bench ?? 0,
    });
    const calculateSiltPercent = useSiltPercentCalculation();

    const siltPercent = useMemo(() => {
        const siltHeight = characteristics.siltHeight;
        const sandHeight = characteristics.sandHeight;

        return calculateSiltPercent({ siltHeight, sandHeight });
    }, [calculateSiltPercent, characteristics.sandHeight, characteristics.siltHeight]);

    const handleTurbidityChange = (event) => {
        const turbidity = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, turbidity }));
    };
    const handleRoundnessChange = (event) => {
        const roundness = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, roundness }));
    };

    const handleSphericityChange = (event) => {
        const sphericity = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, sphericity }));
    };

    const handleSandHeightChange = (event) => {
        const sandHeight = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, sandHeight }));
    };

    const handleSiltHeightChange = (event) => {
        const siltHeight = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, siltHeight }));
    };

    const handleAcidSolubilityChange = (event) => {
        const acidSolubility = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, acidSolubility }));
    };

    const handlePitIdChange = (event) => {
        const pitId = event.target.value;
        setCharacteristics((prevState) => ({ ...prevState, pitId }));
    };
    const handleBlockChange = (event) => {
        const block = event.target.value;
        setCharacteristics((prevState) => ({ ...prevState, block }));
    };
    const handleBenchChange = (event) => {
        const bench = parseFloat(event.target.value);
        setCharacteristics((prevState) => ({ ...prevState, bench }));
    };

    useEffect(() => {
        setSample((prevState) => ({ ...prevState, ...characteristics, siltPercent }));
    }, [characteristics, setSample, siltPercent]);

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {showTitle && (
                                <Grid item xs={12}>
                                    <Typography variant="h6">Characteristics</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Turbidity (NTU)"
                                            type="number"
                                            onChange={handleTurbidityChange}
                                            value={characteristics.turbidity}
                                            fullWidth
                                            disabled={isReadOnly || disabled}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Roundness"
                                            type="number"
                                            onChange={handleRoundnessChange}
                                            value={characteristics.roundness}
                                            fullWidth
                                            disabled={isReadOnly || disabled}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Sphericity"
                                            type="number"
                                            onChange={handleSphericityChange}
                                            value={characteristics.sphericity}
                                            fullWidth
                                            disabled={isReadOnly || disabled}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Acid Solubility"
                                            type="number"
                                            value={characteristics.acidSolubility}
                                            onChange={handleAcidSolubilityChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={isReadOnly || disabled}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Sand Height"
                                            type="number"
                                            onChange={handleSandHeightChange}
                                            value={characteristics.sandHeight}
                                            fullWidth
                                            disabled={isReadOnly || disabled}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Silt Height"
                                            type="number"
                                            onChange={handleSiltHeightChange}
                                            value={characteristics.siltHeight}
                                            fullWidth
                                            disabled={isReadOnly || disabled}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Silt Percent"
                                            type="number"
                                            value={siltPercent}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            disabled={isReadOnly || disabled}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Pit ID"
                                            value={characteristics.pitId}
                                            onChange={handlePitIdChange}
                                            disabled={isReadOnly || disabled}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Block"
                                            value={characteristics.block}
                                            disabled={isReadOnly || disabled}
                                            onChange={handleBlockChange}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VividTextField
                                            label="Bench"
                                            type="number"
                                            value={characteristics.bench}
                                            onChange={handleBenchChange}
                                            disabled={isReadOnly || disabled}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export const SampleCharacteristicsSkeleton = () => {
    return <Skeleton />;
};
