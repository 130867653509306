import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemButton,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import {
    useAddSampleGroup,
    useGetCharacteristicSummations,
    useGetCompositeTypes,
    useGetRangeSummations,
    useGetSampleLocations,
    useGetSamplesByFilter,
    useGetSamplesExport,
    useGetSamplesExportByFilters,
    useGetSieveSummations,
    useGetSieveSetAnalysisById,
    useGetSpecificSampleLocations,
    useGetTestTypes,
    useVoidSampleMutation,
} from "../../queries/QualityControlQueries";
import ReplayIcon from "@mui/icons-material/Replay";
import {
    DataGrid,
    GridActionsCellItem,
    GridColumns,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams, Link as RouterLink } from "react-router-dom";
import {
    AddCircle,
    Block,
    Close,
    CopyAll,
    Edit,
    FilterAlt,
    Launch,
    LinkOff,
    LinkOutlined,
    LocationOn,
    OpenInFull,
    OpenInNewOutlined,
    Repeat,
    SaveAlt,
    Search,
    Visibility,
} from "@mui/icons-material";
import { isEqual } from "lodash";
import { DateRangeIcon, DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { SampleStatus } from "../../types/QualityControlEnums";
import { EntitySelect } from "../general/EntitySelect";
import { useGetUsers, useGetPlants, useGetSites, useGetUsersInGroup } from "../../queries/AdminQueries";
import {
    Range,
    Sample,
    SampleFilters,
    SampleGroup,
    SampleSummation,
    SieveAnalysis,
    SpecificLocation,
} from "../../types/QualityControlModels";
import { SelectOption } from "../../utilities/SelectUtilities";
import { AddSampleForm } from "./QualityControlAddSampleGroup";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useRelativeTime } from "../../hooks/general/RelativeTime";
import { GrayTableCell } from "./subComponents/styled";
import { useUserInfo } from "../../hooks/general/useUserInfo";
import { PaperItem } from "../../utilities/PaperItem";
import { SampleStatusChip, SampleStatusDisplay } from "./subComponents/SampleStatusChip";
import { displayLocalTimestamp } from "../../hooks/general/dayjs";
import { UserSelect } from "../general/UserSelect";
import { useUserStore } from "../../store/UserStore";
import { useUserHasPermission } from "../../hooks/auth/useUserHasPermission";
import { PERMISSIONS } from "../../hooks/auth/useUserRoles";
import { sortSieveStatistics } from "../../utilities/SortUtilities";

dayjs.extend(utc);
dayjs.extend(timezone);

export const QualityControlSampleDatabase = () => {
    const { siteCode } = useParams();
    const navigate = useNavigate();
    const userCanEdit = useUserHasPermission(PERMISSIONS.EDIT)
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
    const { today, yesterday, lastWeek } = useRelativeTime();
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const [selectedRows, setSelectedRows] = useState([]);
    const { mutate: addSampleGroup, isLoading: isLoadingAddSampleGroup } = useAddSampleGroup();
    const [sampleFilters, setSampleFilters] = useState<SampleFilters>({
        startDate: searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : yesterday,
        endDate: searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : today,
        statuses: searchParams.get("statuses")
            ? searchParams.getAll("statuses")?.map((status) => Number(status))
            : null,
        testTypes: searchParams.get("testTypes")
            ? searchParams.getAll("testTypes")?.map((testType) => Number(testType))
            : null,
        compositeTypes: null,
        labTechs: null,
        samplers: null,
        operators: null,
        sites: null,
        plants: null,
        locations: null,
        specificLocations: null,
        viewModeToggle: "simple",
    });
    const [compareSample, setCompareSample] = useState<Sample>(null);
    const [openCompareDialog, setOpenCompareDialog] = useState(false);
    const [compareView, setCompareView] = useState("sieves");

    const handleViewModeChange = (event, newViewMode) => {
        if (newViewMode !== null) {
            // Prevent deselection
            setCompareView(newViewMode);
        }
    };
    const handleCompareDialogOpen = (sample) => {
        setOpenCompareDialog(true);
        setCompareSample(sample);
    };

    const handleCompareDialogClose = () => {
        setOpenCompareDialog(false);
        setCompareSample(null);
    };
    const { mutate: voidSample, isLoading: isVoidingSample } = useVoidSampleMutation(sampleFilters);
    const { userInfo } = useUserInfo();
    const {
        data: samplesByFilter,
        isLoading: isLoadingSamplesByFilter,
        isError,
        refetch,
        isRefetching,
    } = useGetSamplesByFilter(sampleFilters);

    const { data: summations, isLoading: isLoadingSummations } = useGetSieveSummations(sampleFilters);

    const columns: GridColumns<Sample> = [
        {
            field: "id",
            headerName: "ID",
            flex: 2,
            renderCell: (params) => (
                <Link component={RouterLink} sx={{ textDecoration: "none" }} to={`./${params.value}/view`}>
                    {params.value}
                </Link>
            ),
        },
        { field: "plantName", headerName: "Plant" },
        {
            field: "locationName",
            headerName: "Location",
            flex: 2,
        },
        { field: "testTypeName", headerName: "Test Type", flex: 2 },
        {
            field: "compositeTypeName",
            headerName: "Composite Type",
            flex: 2,
        },
        {
            field: null,
            headerName: "Sieve Analysis",
            sortable: false,
            filterable: false,
            flex: 2,
            renderCell: ({ row }: { row: Sample }) =>
                row.sieveSetAnalysisId ? (
                    <Box
                        onClick={() => handleCompareDialogOpen(row)}
                        sx={(theme) => ({ cursor: "pointer", color: theme.palette.primary.main })}
                    >
                        Compare
                    </Box>
                ) : null,
        },
        {
            field: "createdByName",
            headerName: "Created By",
            flex: 2,
        },
        {
            field: "sampledTimestampUtc",
            headerName: "Sampled At",
            flex: 2.5,
            renderCell: (params) => {
                return displayLocalTimestamp(params.value).format("MM/DD/YYYY hh:mm A");
            },
        },
        {
            field: "status",
            headerName: "Status",
            flex: 2,
            renderCell: (params) => <SampleStatusDisplay status={params.value} />,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            flex: 3,
            cellClassName: "actions",
            getActions: (params) => {
                return userCanEdit ? [
                    <GridActionsCellItem
                        {...({} as any)}
                        icon={<Edit />}
                        onClick={() => navigate(`./${params.id}/edit`)}
                        label="Edit"
                        showInMenu
                    />,
                    <GridActionsCellItem
                        {...({} as any)}
                        label="Repeat"
                        icon={<CopyAll />}
                        onClick={() => handleRepeatSample(params.row)}
                        disabled={params.row.status !== SampleStatus.Complete}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        {...({} as any)}
                        icon={<Block />}
                        label="Void"
                        onClick={() => handleVoidSample(params.row.id)}
                        //disabled={params.row.status !== SampleStatus.Complete}
                        showInMenu
                    />,
                ] : [ <GridActionsCellItem
                    {...({} as any)}
                    icon={<Visibility />}
                    onClick={() => navigate(`./${params.id}/view`)}
                    label="View"
                    showInMenu
                />];
            },
        },
    ];

    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const handleRepeatSample = useCallback(
        (sample: Sample) => {
            const {
                id,
                labTech,
                sampleGroupId,
                testType,
                location,
                compositeType,
                crushAnalyses,
                crushAnalysisId,
                plantSettings,
                sieveSetAnalysis,
                sieveSetAnalysisId,
                createdBy,
                sampledTimestamp,
                ...rest
            } = sample;
            const repeatSample = {
                ...rest,
                labTech: userInfo.id,
                createdBy: userInfo.id,
                sampledTimestamp: new Date(),
                isRepeat: Number(true),
                repeatSampleId: sample?.id,
                status: SampleStatus.New,
                statusString: SampleStatus[SampleStatus.New],
            } as Sample;

            const sampleGroup: SampleGroup = {
                plantId: sample?.location?.plantId,
                samples: [repeatSample],
                createdBy: userInfo.id,
                createdByName: userInfo.name,
                sampledTimestamp: new Date(),
                sampledTimestampUtc: new Date().toISOString(),
            };

            addSampleGroup(
                { sampleGroup },
                {
                    onSuccess: (response) => {
                        toast(
                            <>
                                <Typography>Sample created successfully</Typography>
                                <Typography>
                                    <a href={`./samples/${response.id}/edit`}>Go to Sample</a>
                                </Typography>
                            </>,

                            { type: "success" }
                        );
                    },
                }
            );
        },
        [addSampleGroup, userInfo]
    );

    const handleVoidSample = useCallback(
        (sampleId) => {
            voidSample(sampleId, {
                onSuccess: () => toast(`Sample ${sampleId} voided successfully`, { type: "success" }),
            });
        },
        [voidSample]
    );

    useEffect(() => {
        if (siteCode && sites) {
            const site = sites?.find((site) => site.code === siteCode);
            setSampleFilters((prevState) => ({ ...prevState, sites: [Number(site?.id)] }));
        }
    }, [siteCode, sites, setSampleFilters]);

    useEffect(() => {
        setSearchParams(
            new URLSearchParams({
                startDate: sampleFilters.startDate.toJSON(),
                endDate: sampleFilters.endDate.toJSON(),
                statuses: sampleFilters.statuses?.join(",") ?? "",
                testTypes: sampleFilters.testTypes?.join(",") ?? "",
                compositeTypes: sampleFilters.compositeTypes?.join(",") ?? "",
                labTechs: sampleFilters.labTechs?.join(",") ?? "",
                samplers: sampleFilters.samplers?.join(",") ?? "",
                operators: sampleFilters.operators?.join(",") ?? "",
                sites: sampleFilters.sites?.join(",") ?? "",
                plants: sampleFilters.plants?.join(",") ?? "",
                locations: sampleFilters.locations?.join(",") ?? "",
                specificLocations: sampleFilters.specificLocations?.join(",") ?? "",
                viewModeToggle: "simple",
            })
        );
    }, [sampleFilters, setSearchParams]);

    return (
        <>
            {compareSample && (
                <CompareSampleDialog
                    openCompareDialog={openCompareDialog}
                    handleCompareDialogClose={handleCompareDialogClose}
                    compareSample={compareSample}
                    compareView={compareView}
                    handleViewModeChange={handleViewModeChange}
                    sampleFilters={sampleFilters}
                    summations={summations}
                    isLoadingSummations={isLoadingSummations}
                />
            )}
            <Stack spacing={2} maxHeight="100vh">
                <PaperItem>
                    <SampleFiltersDisplay sampleFilters={sampleFilters} setSampleFilters={setSampleFilters} />
                </PaperItem>
                <PaperItem>
                    <SummationsTable summations={summations} isLoading={isLoadingSummations} />
                </PaperItem>

                <PaperItem>
                    <Grid container height="59vh">
                        {!samplesByFilter?.length && isError ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>Error fetching samples.</Typography>
                                    <Button onClick={() => refetch()} endIcon={<ReplayIcon />}>
                                        Retry
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <DataGrid
                                density="compact"
                                columns={columns}
                                loading={isLoadingSamplesByFilter || isRefetching || !samplesByFilter}
                                rows={samplesByFilter ?? []}
                                paginationMode="server"
                                rowCount={samplesByFilter?.length ?? 0}
                                pageSize={paginationModel.pageSize}
                                // onRowDoubleClick={(row) => navigate(`./${row.id}/edit`)}
                                initialState={{ sorting: { sortModel: [{ field: "id", sort: "desc" }] } }}
                                onPageChange={(pageNumber) =>
                                    setPaginationModel((prevState) => ({
                                        page: pageNumber + 1,
                                        pageSize: prevState.pageSize,
                                    }))
                                }
                                onPageSizeChange={(pageSize) =>
                                    setPaginationModel((prevState) => ({
                                        page: prevState.page,
                                        pageSize,
                                    }))
                                }
                                components={{
                                    Toolbar: SamplesTableToolbar,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        samples: samplesByFilter,
                                        title: "Samples",
                                        sampleFilters,
                                        selectedRows,
                                    },
                                }}
                                checkboxSelection
                                disableSelectionOnClick
                                onSelectionModelChange={handleSelectionChange}
                                sx={{ border: "none" }}
                            />
                        )}
                    </Grid>
                </PaperItem>
            </Stack>
        </>
    );
};

const SummationsTable = ({ summations, isLoading }: { summations: SampleSummation[]; isLoading: boolean }) => {
    const sortedSummations = useMemo(() => sortSieveStatistics(summations), [summations]);
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6">Summations</Typography>
                        </TableCell>
                        {sortedSummations?.map((summation, index) => (
                            <TableCell key={index}>{summation?.sieveSize}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {summations?.length ? (
                        <>
                            <TableRow>
                                <TableCell>Avg</TableCell>
                                {sortedSummations?.map((summation, index) => (
                                    <TableCell key={index}>{summation?.average}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell>Min</TableCell>
                                {sortedSummations?.map((summation, index) => (
                                    <TableCell key={index}>{summation?.min}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell>Max</TableCell>
                                {sortedSummations?.map((summation, index) => (
                                    <TableCell key={index}>{summation?.max}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell>Std Dev</TableCell>
                                {sortedSummations?.map((summation, index) => (
                                    <TableCell key={index}>{summation?.standardDeviation}</TableCell>
                                ))}
                            </TableRow>
                        </>
                    ) : (
                        <TableRow>
                            {isLoading ? (
                                <TableCell>
                                    Loading aggregations <CircularProgress size={24} />
                                </TableCell>
                            ) : (
                                <TableCell>No completed samples in range to aggregate.</TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SamplesTableToolbar = ({ samples, title = null, sampleFilters, selectedRows }) => {
    const { mutate: getSamplesExportCsv } = useGetSamplesExport();
    const { mutate: getAllSamplesExportCsv } = useGetSamplesExportByFilters();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [sampleIdSearchInput, setSampleIdSearchInput] = useState("");
    const [showAddSampleModal, setShowAddSampleModal] = useState(false);
    const handleShowModal = () => setShowAddSampleModal(true);
    const handleCloseModal = () => setShowAddSampleModal(false);
    const handleSaveSuccess = () => {
        queryClient.invalidateQueries(["filteredSamples"], sampleFilters);
        handleCloseModal();
    };

    const handleExportSelectedSamples = useCallback(
        (ids) => {
            const exportPromise = new Promise<void>((resolve, reject) => {
                getSamplesExportCsv(ids, {
                    onSuccess: (data, variables) => {
                        const csvData = new Blob([data], { type: "text/csv" });
                        const downloadLink = document.createElement("a");
                        const url = window.URL.createObjectURL(csvData);

                        downloadLink.href = url;
                        downloadLink.download = `SamplesExport_${new Date().toISOString()}.csv`;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        resolve();
                    },
                    onError: (error) => {
                        reject(error);
                    },
                });
            });

            toast.promise(exportPromise, {
                pending: "Creating export...",
                //success: 'Export created successfully. Check your downloads folder.',
                error: "Export creation failed.",
            });
        },
        [getSamplesExportCsv]
    );

    const handleSampleIdInputChange = useCallback((e) => {
        setSampleIdSearchInput(e.target.value);
    }, []);

    const handleSearchClick = useCallback(() => {
        navigate(`./${sampleIdSearchInput}/edit`);
    }, [navigate, sampleIdSearchInput]);

    const handleExportAllSamples = useCallback(() => {
        const exportPromise = new Promise<void>((resolve, reject) => {
            getAllSamplesExportCsv(sampleFilters, {
                onSuccess: (data, variables) => {
                    const csvData = new Blob([data], { type: "text/csv" });
                    const downloadLink = document.createElement("a");
                    const url = window.URL.createObjectURL(csvData);

                    downloadLink.href = url;
                    downloadLink.download = `SamplesExport_${new Date().toISOString()}.csv`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                },
            });
        });

        toast.promise(exportPromise, {
            pending: "Creating export...",
            //success: 'Export created successfully. Check your downloads folder.',
            error: "Export creation failed.",
        });
    }, [getAllSamplesExportCsv, sampleFilters]);

    const AddSampleDialog = () => {
        return (
            <Dialog open={showAddSampleModal} onClose={handleCloseModal}>
                <DialogTitle>Add Sample</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <AddSampleForm onSuccess={handleSaveSuccess} />
                </DialogContent>
            </Dialog>
        );
    };
    return (
        <Box>
            <AddSampleDialog />
            <GridToolbarContainer>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Grid item marginLeft={1.5}>
                                <Typography variant="h6">{title}</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton title="Add Sample" onClick={handleShowModal}>
                                    <AddCircle />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}></Grid>
                            <Grid item>
                                <TextField
                                    hiddenLabel
                                    placeholder="Go to sample"
                                    size="small"
                                    value={sampleIdSearchInput}
                                    onChange={handleSampleIdInputChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleSearchClick}
                                                    edge="end"
                                                    disabled={!sampleIdSearchInput}
                                                >
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ marginLeft: 1 }}>
                            <GridToolbarColumnsButton {...({} as any)} />
                            <GridToolbarFilterButton {...({} as any)} />

                            <Button
                                size="small"
                                startIcon={<SaveAlt fontSize="small" />}
                                onClick={handleExportAllSamples}
                            >
                                Export All
                            </Button>
                            <Button
                                size="small"
                                startIcon={<SaveAlt fontSize="small" />}
                                onClick={() => handleExportSelectedSamples(selectedRows)}
                                disabled={!selectedRows?.length}
                            >
                                Export {selectedRows?.length ?? 0} Samples
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        </Box>
    );
};

const SampleFiltersDisplay = ({ sampleFilters, setSampleFilters }) => {
    const { today, yesterday, lastWeek } = useRelativeTime();
    const [addFilterAnchorEl, setAddFilterAnchorEl] = useState(null);
    const [viewModeToggleAnchorEl, setViewModeToggleAnchorEl] = useState(null);
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const { data: plants, isLoading: isLoadingPlants } = useGetPlants();
    const { data: locations, isLoading: isLoadingLocations } = useGetSampleLocations();
    const { data: specificLocations, isLoading: isLoadingSpecificLocations } = useGetSpecificSampleLocations();
    const { data: testTypes, isLoading: isLoadingTestTypes } = useGetTestTypes();
    const { data: compositeTypes, isLoading: isLoadingCompositeTypes } = useGetCompositeTypes();

    //const { data: users, isLoading: isLoadingUsers } = useGetUsers();

    const handleOpenViewToggle = useCallback((e: React.MouseEvent) => {
        setViewModeToggleAnchorEl(e.currentTarget);
    }, []);

    const handleOpenAddFilter = useCallback((e: React.MouseEvent) => {
        setAddFilterAnchorEl(e.currentTarget);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setAddFilterAnchorEl(null);
    }, []);

    const handleCloseViewModeToggle = useCallback(() => {
        setViewModeToggleAnchorEl(null);
    }, []);

    const resetFilters = () => {
        setSampleFilters((prevState) => ({
            ...prevState,
            statuses: null,
            testTypes: null,
            compositeTypes: null,
            labTechs: null,
            samplers: null,
            operators: null,
            plants: null,
            locations: null,
            specificLocations: null,
        }));
    };

    //#region Filter Components
    const FilterLabel = ({ label }) => {
        return <Typography variant="subtitle2">{label}</Typography>;
    };

    const ChipFilterPopover = ({ id, label, icon = null, filter = null, filterActive, children }) => {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const handleClick = useCallback(
            (e: React.MouseEvent<any>) => {
                setAnchorEl(e.currentTarget);
            },
            [setAnchorEl]
        );

        const handleClose = useCallback((e) => {
            setAnchorEl(null);
        }, []);

        const handleRemoveFilter = useCallback((e) => {
            const filterName = id.substring(0, id.indexOf("Filter"));
            setSampleFilters((prevState) => ({ ...prevState, [filterName]: null }));
        }, []);

        return filterActive ? (
            <>
                <Badge badgeContent={filter?.length ?? undefined} color="secondary">
                    <Chip
                        id={id}
                        label={label}
                        icon={icon}
                        clickable
                        onClick={handleClick}
                        onDelete={filter?.length === 0 ? handleRemoveFilter : undefined}
                    />
                </Badge>

                <Popover
                    id={id}
                    open={anchorEl?.id === id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={{ width: "25vw" }}>
                                <CardHeader
                                    sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
                                    title={
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <FilterAlt fontSize="small" />
                                            <Typography variant="subtitle2">{label}</Typography>
                                        </Stack>
                                    }
                                />
                                <CardContent>{children}</CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Popover>
            </>
        ) : (
            <></>
        );
    };

    const DateFilter = () => {
        const [dateRange, setDateRange] = useState({
            startDate: sampleFilters.startDate,
            endDate: sampleFilters.endDate,
        });

        const isDateFilterChanged = useMemo(
            () => !isEqual({ ...dateRange }, { startDate: sampleFilters.startDate, endDate: sampleFilters.endDate }),
            [dateRange]
        );
        const handleStartDateChange = useCallback(
            (startDate: Dayjs) => setDateRange((prevState) => ({ ...prevState, startDate: startDate.toDate() })),
            []
        );

        const handleEndDateChange = useCallback(
            (endDate: Dayjs) => setDateRange((prevState) => ({ ...prevState, endDate: endDate.toDate() })),
            []
        );

        const handleApply = useCallback(() => {
            setSampleFilters((prevState) => ({ ...prevState, ...dateRange }));
        }, [dateRange]);

        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DateTimePicker
                        label="Start Date"
                        onChange={handleStartDateChange}
                        value={dayjs(dateRange.startDate)}
                    />
                    <Stack direction="row" spacing={1}>
                        <small>
                            <Link
                                sx={{ cursor: "pointer" }}
                                onClick={() => setDateRange((prevState) => ({ ...prevState, startDate: yesterday }))}
                            >
                                Yesterday
                            </Link>
                        </small>{" "}
                        <small>
                            <Link
                                sx={{ cursor: "pointer" }}
                                onClick={() => setDateRange((prevState) => ({ ...prevState, startDate: lastWeek }))}
                            >
                                Last week
                            </Link>
                        </small>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker label="End Date" onChange={handleEndDateChange} value={dayjs(dateRange.endDate)} />
                    <Stack direction="row" spacing={1} marginLeft={0}>
                        <small>
                            <Link
                                sx={{ cursor: "pointer" }}
                                onClick={() => setDateRange((prevState) => ({ ...prevState, endDate: today }))}
                            >
                                Today
                            </Link>
                        </small>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={!isDateFilterChanged}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const StatusFilter = () => {
        const [statuses, setStatuses] = useState(sampleFilters.statuses);
        const statusOptions = [
            { label: SampleStatus[SampleStatus.New], value: SampleStatus.New },
            { label: SampleStatus[SampleStatus.InProgress], value: SampleStatus.InProgress },
            { label: SampleStatus[SampleStatus.Complete], value: SampleStatus.Complete },
            { label: SampleStatus[SampleStatus.Voided], value: SampleStatus.Voided },
            { label: SampleStatus[SampleStatus.Error], value: SampleStatus.Error },
        ];
        const isFilterChanged = useMemo(() => !isEqual(statuses, sampleFilters.statuses), [statuses]);
        const handleChange = useCallback(
            (event: never, options: SelectOption[]) => setStatuses(options.map((option) => Number(option.value))),
            []
        );
        const handleApply = () => {
            setSampleFilters((prevState) => ({ ...prevState, statuses }));
        };
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => {
                            return option?.value === value?.value;
                        }}
                        size="small"
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Status" fullWidth />}
                        multiple
                        value={
                            statuses?.map((status) => ({
                                label: SampleStatus[status],
                                value: status,
                            })) ?? []
                        }
                        onChange={handleChange}
                        options={statusOptions}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={!isFilterChanged}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const SampleFilter = ({ initialValue, baseEntities, isLoadingBaseEntity, filterName, title }) => {
        const [selectedValues, setSelectedValues] = useState(initialValue);
        const isFilterChanged = useMemo(() => !isEqual(selectedValues, initialValue), [selectedValues]);

        const handleChange = useCallback(
            (selectedOptions) => setSelectedValues(selectedOptions.map((option) => Number(option.value))),
            []
        );

        const handleApply = () => {
            setSampleFilters((prevState) => ({ ...prevState, [filterName]: selectedValues }));
        };

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <EntitySelect
                        label={title}
                        isMulti
                        entities={baseEntities}
                        selectedValues={selectedValues || []}
                        isLoadingEntities={isLoadingBaseEntity}
                        handleChange={handleChange}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={!isFilterChanged}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const UsersFilter = ({ initialSampleFilter, filterName }) => {
        const [selectedValues, setSelectedValues] = useState(initialSampleFilter);
        //const { data: users, isLoading: isLoadingUsers } = useGetUsersInGroup();
        //const options = users?.map((user) => ({ label: user.nickName, value: user.userId }));
        const isFilterChanged = useMemo(() => !isEqual(selectedValues, initialSampleFilter), [selectedValues]);

        const handleChange = useCallback(
            (_, selectedOptions) => setSelectedValues(selectedOptions.map((option) => option.value)),
            []
        );

        const handleApply = () => {
            setSampleFilters((prevState) => ({ ...prevState, [filterName]: selectedValues }));
        };

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <UserSelect selectedUser={selectedValues} handleUserChange={handleChange} disabled={false} />
                    {/* <Autocomplete
                        size="small"
                        isOptionEqualToValue={(option, value) => option?.value === value}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Lab Tech" fullWidth />
                        )}
                        multiple
                        value={selectedValues.map((userId) => ({
                            label: users?.find((user) => user.userId === userId)?.nickName,
                            value: userId,
                        }))}
                        onChange={handleChange}
                        options={options}
                    /> */}
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={!isFilterChanged}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const LocationFilter = () => {
        const [selectedSites, setSelectedSites] = useState(sampleFilters.sites);
        const [selectedPlants, setSelectedPlants] = useState(sampleFilters.plants);
        const [selectedLocations, setSelectedLocations] = useState(sampleFilters.locations);
        const [selectedSpecificLocations, setSelectedSpecificLocations] = useState(sampleFilters.specificLocations);

        const isFilterChanged = useMemo(() => isEqual({}, {}), []);

        const plantOptions = useMemo(() => {
            const filteredPlants = plants?.filter((plant) => selectedSites?.indexOf(plant.siteId) !== -1);
            return filteredPlants?.map((filteredPlant) => ({
                label: filteredPlant?.name,
                value: filteredPlant?.id,
            }));
        }, [selectedSites]);

        const selectedPlantOptions = useMemo(() => {
            return selectedPlants?.map((plantId) => {
                const plantEntity = plants?.find((plant) => plant.id === plantId);
                return {
                    label: plantEntity.name,
                    value: plantId,
                };
            });
        }, [selectedPlants]);

        const locationOptions = useMemo(() => {
            const filteredLocations = locations?.filter((location) => selectedPlants?.indexOf(location.plantId) !== -1);

            return filteredLocations?.map((filteredLocation) => ({
                label: filteredLocation?.name,
                value: filteredLocation?.id,
            }));
        }, [selectedPlants]);

        const selectedLocationOptions = useMemo(() => {
            return selectedLocations?.map((locationId) => {
                const locationEntity = locations?.find((location) => location.id === locationId);
                return {
                    label: locationEntity?.name,
                    value: locationEntity.id,
                };
            });
        }, [selectedLocations]);

        const specificLocationOptions = useMemo(() => {
            const filteredSpecificLocations = specificLocations?.filter(
                (specificLocation) => selectedSpecificLocations?.indexOf(specificLocation.locationId) !== -1
            );

            if (filteredSpecificLocations?.length > 0) {
                return filteredSpecificLocations?.map((filteredSpecificLocation) => ({
                    label: filteredSpecificLocation?.name,
                    value: filteredSpecificLocation?.id,
                }));
            }
        }, [selectedSpecificLocations]);

        const selectedSpecificLocationOptions = useMemo(() => {
            return selectedSpecificLocations?.map((specificLocationId) => {
                const specificLocationEntity = specificLocations?.find(
                    (specificLocation: SpecificLocation) => specificLocation?.id === specificLocationId
                );
                return {
                    label: specificLocationEntity?.name,
                    value: specificLocationEntity?.id,
                };
            });
        }, []);

        const handleSiteChange = useCallback(
            (selectedOptions: SelectOption[]) =>
                setSelectedSites(selectedOptions?.map((option) => Number(option.value))),
            []
        );

        const handlePlantChange = useCallback(
            (event: never, selectedOptions: SelectOption[]) =>
                setSelectedPlants(selectedOptions.map((option) => Number(option.value))),
            []
        );

        const handleLocationChange = useCallback(
            (event: never, selectedOptions: SelectOption[]) =>
                setSelectedLocations(selectedOptions.map((option) => Number(option.value))),
            []
        );
        const handleSpecificLocationChange = useCallback(
            (event: never, selectedOptions: SelectOption[]) =>
                setSelectedSpecificLocations(selectedOptions.map((option) => Number(option.value))),
            []
        );

        const handleApply = useCallback(() => {
            setSampleFilters((prevState) => ({
                ...prevState,
                sites: selectedSites,
                plants: selectedPlants,
                locations: selectedLocations,
                specificLocations: selectedSpecificLocations,
            }));
        }, [selectedSites, selectedPlants, selectedLocations, selectedSpecificLocations]);

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <EntitySelect
                        label="Site"
                        isMulti
                        entities={sites}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={selectedSites}
                        handleChange={handleSiteChange}
                        size="small"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Autocomplete
                            limitTags={3}
                            multiple={true}
                            options={plantOptions ?? []}
                            getOptionLabel={(option) => option?.label ?? ""}
                            onChange={handlePlantChange}
                            isOptionEqualToValue={(option, value) => {
                                return option?.value === value?.value;
                            }}
                            value={selectedPlantOptions}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Plant" fullWidth />
                            )}
                            loading={isLoadingPlants}
                            loadingText={<CircularProgress size={24} />}
                            disabled={isLoadingPlants}
                            size="small"
                            fullWidth
                            noOptionsText="Select a Site"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Autocomplete
                            limitTags={3}
                            multiple
                            options={locationOptions ?? []}
                            getOptionLabel={(option) => option?.label ?? ""}
                            onChange={handleLocationChange}
                            isOptionEqualToValue={(option, value) => {
                                return option?.value === value?.value;
                            }}
                            value={selectedLocationOptions}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Location" fullWidth />
                            )}
                            loading={isLoadingLocations}
                            loadingText={<CircularProgress size={24} />}
                            disabled={isLoadingLocations}
                            size="small"
                            fullWidth
                            noOptionsText="Select a Plant"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Autocomplete
                            limitTags={3}
                            multiple={true}
                            options={specificLocationOptions ?? []}
                            getOptionLabel={(option) => option?.label ?? ""}
                            onChange={handleSpecificLocationChange}
                            isOptionEqualToValue={(option, value) => {
                                return option?.value === value?.value;
                            }}
                            value={selectedSpecificLocationOptions}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Specific Location" fullWidth />
                            )}
                            loading={isLoadingSpecificLocations}
                            loadingText={<CircularProgress size={24} />}
                            disabled={isLoadingSpecificLocations}
                            size="small"
                            fullWidth
                            noOptionsText="Select a Location"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={!isFilterChanged}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const sitesFilterLabel = useMemo(() => {
        const sitesSection = sampleFilters.sites?.length
            ? sites
                ?.filter((site) => sampleFilters.sites?.indexOf(site.id) !== -1)
                ?.map((site) => site?.code?.toUpperCase())
            : "All Sites";

        const plantsSection = plants
            ?.filter((plant) => sampleFilters.plants?.indexOf(plant.id) !== -1)
            ?.map((plant) => plant.name);

        const locationsSection = locations
            ?.filter((location) => sampleFilters.locations?.indexOf(location.id) !== -1)
            ?.map((location) => location.name);

        return `${sitesSection} ${sampleFilters.plants?.length > 0 ? " | " + plantsSection : ""} ${sampleFilters.locations?.length > 0 ? " | " + locationsSection : ""
            }`;
    }, [sampleFilters.sites, sampleFilters.plants, sampleFilters.locations, sampleFilters.specificLocations]);

    //#endregion

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={10} sx={{ paddingTop: "0px !important" }}>
                <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    textAlign="justify"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                >
                    <Typography variant="h6">Filters</Typography>
                    <IconButton title="Add Filter" id="addFilter" onClick={handleOpenAddFilter}>
                        <AddCircle />
                    </IconButton>

                    <Popover
                        id="addFilter"
                        open={addFilterAnchorEl?.id === "addFilter"}
                        anchorEl={addFilterAnchorEl}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "right",
                        }}
                    >
                        <DialogContent sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}>
                            <Typography variant="subtitle2">Add Filter</Typography>
                        </DialogContent>
                        <List sx={{ pt: 0 }} disablePadding>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={sampleFilters.statuses !== null || !!sampleFilters.statuses}
                                    onClick={() => setSampleFilters((prevState) => ({ ...prevState, statuses: [] }))}
                                >
                                    Status
                                </ListItemButton>
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={sampleFilters.testTypes !== null || !!sampleFilters.testTypes}
                                    onClick={() => setSampleFilters((prevState) => ({ ...prevState, testTypes: [] }))}
                                >
                                    Test Type
                                </ListItemButton>
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={!!sampleFilters.compositeTypes}
                                    onClick={() =>
                                        setSampleFilters((prevState) => ({ ...prevState, compositeTypes: [] }))
                                    }
                                >
                                    Composite Type
                                </ListItemButton>
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={!!sampleFilters.labTechs}
                                    onClick={() => setSampleFilters((prevState) => ({ ...prevState, labTechs: [] }))}
                                >
                                    Lab Techs
                                </ListItemButton>
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={!!sampleFilters.operators}
                                    onClick={() => setSampleFilters((prevState) => ({ ...prevState, operators: [] }))}
                                >
                                    Operators
                                </ListItemButton>
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemButton
                                    selected={!!sampleFilters.samplers}
                                    onClick={() => setSampleFilters((prevState) => ({ ...prevState, samplers: [] }))}
                                >
                                    Samplers
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Popover>
                </Stack>
            </Grid>
            <Grid item xs={2}>
                <Stack direction="row" spacing={0} alignItems="center" justifyContent="flex-end">
                    <Typography variant="overline" onClick={resetFilters} sx={{ cursor: "pointer" }}>
                        Reset Filters
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    <ChipFilterPopover
                        id="dateFilter"
                        filter={{
                            startDate: sampleFilters.startDate,
                            endDate: sampleFilters.endDate,
                        }}
                        filterActive={sampleFilters.startDate || sampleFilters.endDate}
                        icon={<DateRangeIcon />}
                        label={
                            <Typography variant="subtitle2">
                                {dayjs(sampleFilters.startDate)?.format("MM/DD/YYYY")}
                                {" - "}
                                {dayjs(sampleFilters.endDate)?.format("MM/DD/YYYY")}
                            </Typography>
                        }
                    >
                        <DateFilter />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="sitesFilter"
                        filter={{ sites: sampleFilters.sites }}
                        filterActive={!!sampleFilters.sites}
                        icon={<LocationOn />}
                        label={<Typography variant="subtitle2">{sitesFilterLabel}</Typography>}
                    >
                        <LocationFilter />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="statusesFilter"
                        filter={sampleFilters.statuses}
                        filterActive={sampleFilters.statuses !== null}
                        label={<FilterLabel label="Status" />}
                    >
                        <StatusFilter />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="testTypesFilter"
                        filter={sampleFilters.testTypes}
                        filterActive={sampleFilters.testTypes !== null}
                        label={<FilterLabel label="Test Type" />}
                    >
                        <SampleFilter
                            initialValue={sampleFilters?.testTypes}
                            baseEntities={testTypes}
                            isLoadingBaseEntity={isLoadingTestTypes}
                            filterName="testTypes"
                            title="Test Type"
                        />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="compositeTypesFilter"
                        filter={sampleFilters.compositeTypes}
                        filterActive={sampleFilters.compositeTypes !== null}
                        label={<FilterLabel label="Composite Type" />}
                    >
                        <SampleFilter
                            initialValue={sampleFilters?.compositeTypes}
                            baseEntities={compositeTypes}
                            isLoadingBaseEntity={isLoadingCompositeTypes}
                            filterName="compositeTypes"
                            title="Composite Type"
                        />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="labTechsFilter"
                        filter={sampleFilters.labTechs}
                        filterActive={sampleFilters.labTechs !== null}
                        label={<FilterLabel label="Lab Tech" />}
                    >
                        <UsersFilter filterName="labTechs" initialSampleFilter={sampleFilters?.labTechs} />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="samplersFilter"
                        filter={sampleFilters.samplers}
                        filterActive={sampleFilters.samplers !== null}
                        label={<FilterLabel label="Sampler" />}
                    >
                        <UsersFilter filterName="samplers" initialSampleFilter={sampleFilters?.samplers} />
                    </ChipFilterPopover>
                    <ChipFilterPopover
                        id="operatorsFilter"
                        filter={sampleFilters.operators}
                        filterActive={sampleFilters.operators !== null}
                        label={<FilterLabel label="Operator" />}
                    >
                        <UsersFilter filterName="operators" initialSampleFilter={sampleFilters?.operators} />
                    </ChipFilterPopover>
                </Stack>
            </Grid>
        </Grid>
    );
};

const SieveComparisonTable = ({
    sieveAnalyses,
    summations,
    oversizePercent,
}: {
    sieveAnalyses: SieveAnalysis[];
    summations: SampleSummation[];
    oversizePercent: number;
}) => {
    const summationMatch = useCallback(
        (sieveName) => summations?.find((summation) => summation.sieveSize === sieveName),
        [summations]
    );

    if (!sieveAnalyses.some((s) => s.sieve?.size === "10")) {
        sieveAnalyses.push({
            percentFinal: Math.round(oversizePercent * 100) / 100,
            sieve: {
                id: 0,
                sieveSetId: 0,
                serial: "",
                size: "10",
                initialWeight: 0,
                currentWeight: 0,
                unitOfMeasure: "grams",
                totalUses: 0,
                displayOrder: 0,
                isPan: false,
                createdTimestamp: new Date("0001-01-01T00:00:00"),
                lastWashTimestamp: new Date("0001-01-01T00:00:00"),
            },
        });
    }

    return (
        <Table size="small" sx={{ minHeight: "500px", minWidth: "600px" }}>
            <TableHead>
                <TableRow>
                    <TableCell>Sieve</TableCell>
                    <TableCell>Percent Final</TableCell>
                    <GrayTableCell>Avg</GrayTableCell>
                    <GrayTableCell>Std</GrayTableCell>
                    <GrayTableCell>Max</GrayTableCell>
                    <GrayTableCell>Min</GrayTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sieveAnalyses
                    ?.sort((a: SieveAnalysis, b: SieveAnalysis) => {
                        if (a.sieve.isPan) return 1;
                        if (b.sieve.isPan) return -1;
                        return Number(a.sieve.displayOrder) - Number(b.sieve.displayOrder);
                    })
                    .map((sieveAnalysis) => (
                        <TableRow key={sieveAnalysis.sieve.size}>
                            <TableCell>
                                <Typography variant="body2" fontWeight={500}>
                                    {sieveAnalysis.sieve.size}
                                </Typography>
                            </TableCell>
                            <TableCell>{sieveAnalysis.percentFinal}</TableCell>
                            <GrayTableCell>{summationMatch(sieveAnalysis.sieve.size)?.average}</GrayTableCell>
                            <GrayTableCell>{summationMatch(sieveAnalysis.sieve.size)?.standardDeviation}</GrayTableCell>
                            <GrayTableCell>{summationMatch(sieveAnalysis.sieve.size)?.max}</GrayTableCell>
                            <GrayTableCell>{summationMatch(sieveAnalysis.sieve.size)?.min}</GrayTableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

const GroupingsComparisonTable = ({ ranges, rangeSummations }) => {
    const rangeMatch = useCallback(
        (rangeName) => rangeSummations?.find((summation) => summation.rangeName === rangeName),
        [rangeSummations]
    );

    return (
        <Table size="small" sx={{ minHeight: "500px", minWidth: "600px" }}>
            <TableHead>
                <TableRow>
                    <TableCell>Grouping</TableCell>
                    <TableCell>Value</TableCell>
                    <GrayTableCell>Avg</GrayTableCell>
                    <GrayTableCell>Std</GrayTableCell>
                    <GrayTableCell>Max</GrayTableCell>
                    <GrayTableCell>Min</GrayTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ranges
                    ?.sort((a: Range, b: Range) => a.rangeSetting.startSieve - b.rangeSetting.startSieve)
                    .map((range: Range) => (
                        <TableRow key={range.id}>
                            <TableCell>
                                <Typography variant="body2" fontWeight={500}>
                                    {range?.rangeSetting?.name}
                                </Typography>
                            </TableCell>
                            <TableCell>{range?.value}</TableCell>
                            <GrayTableCell>{rangeMatch(range?.rangeSetting?.name)?.average}</GrayTableCell>
                            <GrayTableCell>{rangeMatch(range?.rangeSetting?.name)?.standardDeviation}</GrayTableCell>
                            <GrayTableCell>{rangeMatch(range?.rangeSetting?.name)?.max}</GrayTableCell>
                            <GrayTableCell>{rangeMatch(range?.rangeSetting?.name)?.min}</GrayTableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

const CharacteristicsComparisonTable = ({ characteristics, characteristicSummations }) => {
    const characteristicMatch = useCallback(
        (characteristicName) =>
            characteristicSummations?.find((summation) => summation.characteristic === characteristicName),
        [characteristicSummations]
    );

    return (
        <Table size="small" sx={{ minHeight: "500px", minWidth: "600px" }}>
            <TableHead>
                <TableRow>
                    <TableCell>Characteristic</TableCell>
                    <TableCell>Value</TableCell>
                    <GrayTableCell>Avg</GrayTableCell>
                    <GrayTableCell>Std</GrayTableCell>
                    <GrayTableCell>Max</GrayTableCell>
                    <GrayTableCell>Min</GrayTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {characteristics?.map((characteristic: { name: string; value: string }, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Typography variant="body2" fontWeight={500}>
                                {characteristic?.name}
                            </Typography>
                        </TableCell>
                        <TableCell>{characteristic?.value ?? "N/A"}</TableCell>
                        <GrayTableCell>{characteristicMatch(characteristic?.name)?.average}</GrayTableCell>
                        <GrayTableCell>{characteristicMatch(characteristic?.name)?.standardDeviation}</GrayTableCell>
                        <GrayTableCell>{characteristicMatch(characteristic?.name)?.max}</GrayTableCell>
                        <GrayTableCell>{characteristicMatch(characteristic?.name)?.min}</GrayTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const CompareSampleDialog = ({
    openCompareDialog,
    handleCompareDialogClose,
    compareSample,
    compareView,
    handleViewModeChange,
    summations,
    sampleFilters,
    isLoadingSummations,
}) => {
    const { data: compareSampleSieveSetAnalysis, isLoading: isLoadingCompareSampleSieveSetAnalysis } =
        useGetSieveSetAnalysisById(compareSample?.sieveSetAnalysisId);
    const {
        data: rangeSummations,
        isLoading: isLoadingRangeSummations,
        isError: isRangeSummationsError,
    } = useGetRangeSummations(sampleFilters);
    const {
        data: characteristicSummations,
        isLoading: isLoadingCharacteristicSummations,
        isError: isCharacteristicSummationsError,
    } = useGetCharacteristicSummations(sampleFilters);
    return (
        <Dialog
            open={openCompareDialog}
            onClose={handleCompareDialogClose}
            aria-labelledby="sieve-analysis-dialog-title"
            maxWidth="sm"
        >
            <DialogTitle id="sieve-analysis-dialog-title">
                <Link component={RouterLink} sx={{ textDecoration: "none" }} to={`./${compareSample.id}/view`}>
                    Sample {compareSample.id} <Launch fontSize="small" />
                </Link>

                <ToggleButtonGroup
                    size="small"
                    value={compareView}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="View Mode"
                    style={{ marginLeft: 20, float: "right" }}
                >
                    <ToggleButton
                        color="primary"
                        value="sieves"
                        aria-label="sieves"
                        disabled={isLoadingCompareSampleSieveSetAnalysis || isLoadingSummations}
                    >
                        Sieves{" "}
                        {(isLoadingCompareSampleSieveSetAnalysis || isLoadingSummations) && (
                            <CircularProgress size={20} />
                        )}
                    </ToggleButton>
                    <ToggleButton
                        color="primary"
                        value="groupings"
                        aria-label="groupings"
                        disabled={isLoadingRangeSummations || isRangeSummationsError}
                    >
                        Groupings {isLoadingRangeSummations && <CircularProgress size={20} />}
                    </ToggleButton>
                    <ToggleButton
                        color="primary"
                        value="characteristics"
                        aria-label="characteristics"
                        disabled={isLoadingCharacteristicSummations || isCharacteristicSummationsError}
                    >
                        Characteristics {isLoadingCharacteristicSummations && <CircularProgress size={20} />}
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogTitle>
            {compareView === "sieves" && !isLoadingCompareSampleSieveSetAnalysis && !isLoadingSummations && (
                <SieveComparisonTable
                    sieveAnalyses={compareSampleSieveSetAnalysis?.sieveAnalyses}
                    summations={summations}
                    oversizePercent={compareSampleSieveSetAnalysis.oversizePercent}
                />
            )}
            {compareView === "groupings" && !isLoadingRangeSummations && (
                <GroupingsComparisonTable
                    ranges={compareSampleSieveSetAnalysis?.ranges}
                    rangeSummations={rangeSummations}
                />
            )}
            {compareView === "characteristics" && !isLoadingCharacteristicSummations && (
                <CharacteristicsComparisonTable
                    characteristics={[
                        { name: "AcidSolubility", value: compareSample.acidSolubility },
                        { name: "Turbidity", value: compareSample.turbidity },
                        { name: "Sphericity", value: compareSample.sphericity },
                        { name: "Roundness", value: compareSample.roundness },
                        { name: "SandHeight", value: compareSample.sandHeight },
                        { name: "SiltHeight", value: compareSample.siltHeight },
                        { name: "SiltPercent", value: compareSample.siltPercent },
                    ]}
                    characteristicSummations={characteristicSummations}
                />
            )}
            <Button onClick={handleCompareDialogClose}>Close</Button>
        </Dialog>
    );
};
