import create from "zustand";

interface IUserStore {
	userRoles: string[],
	setUserRoles: (roles: string[]) => void,
}

export const useUserStore = create<IUserStore>((set) => ({
	userRoles: [],
	setUserRoles: (userRoles) => set({ userRoles })
}));
