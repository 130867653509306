import { useEffect, useState } from "react";
import { loginRequest } from "../../config/AuthConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const useAcquireAccessToken = (scopes = null) => {
    const [token, setToken] = useState(null);
    const { accounts, instance } = useMsal();

    useEffect(() => {
        const acquireToken = async () => {
            if (!accounts?.[0]) return; 

            try {
                await instance.initialize();
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                    scopes: scopes || loginRequest.scopes,
                });
                setToken(response);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        const response = await instance.acquireTokenPopup({
                            ...loginRequest,
                            scopes: scopes || loginRequest.scopes,
                        });
                        setToken(response);
                    } catch (popupError) {
                        console.error("Token acquisition via popup failed:", popupError);
                    }
                } else {
                    console.error("Silent token acquisition failed:", error);
                }
            }
        };

        acquireToken();
    }, [accounts, instance, scopes]);

    return { token, accessToken: token?.accessToken };
};