import React from "react";
import { CardContent, Grid, Typography, TextField, Card, FormControl, Skeleton } from "@mui/material";
import { LocalizationProvider, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetSites } from "../../../queries/AdminQueries";
import { useGetTestTypes, useGetCompositeTypes } from "../../../queries/QualityControlQueries";
import { UserGroup, UserRole } from "../../../types/QualityControlEnums";
import { EntitySelect } from "../../general/EntitySelect";
import { UserSelect } from "../../general/UserSelect";
import { useMemo, useState } from "react";
import { SelectOption } from "../../../utilities/SelectUtilities";
import { Plant, Sample } from "../../../types/QualityControlModels";
import { isValidNumber } from "./Calculations";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { displayLocalTimestamp } from "../../../hooks/general/dayjs";
import { VividTextField } from "./styled";

dayjs.extend(utc);
dayjs.extend(timezone);

export const SampleDetails = ({
    sampleUpdate,
    setSampleUpdate,
    isReadOnly,
    disabled,
    showTitle = true,
}: {
    sampleUpdate: Sample;
    setSampleUpdate: React.Dispatch<React.SetStateAction<Sample>>;
    isReadOnly: boolean;
    disabled: boolean;
    showTitle: boolean;
}) => {
    const [selectedSite, setSelectedSite] = useState(sampleUpdate?.location?.plant?.siteId);
    const [selectedPlant, setSelectedPlant] = useState(sampleUpdate?.location?.plantId);
    const [selectedSpecificLocation, setSelectedSpecificLocation] = useState(null);
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const { data: testTypes, isLoading: isLoadingTestTypes } = useGetTestTypes();
    const { data: compositeTypes, isLoading: isLoadingCompositeTypes } = useGetCompositeTypes();

    const selectedSiteEntity = useMemo(() => sites?.find((site) => site.id === selectedSite), [selectedSite, sites]);

    const selectedPlantEntity = useMemo<Plant>(
        () => selectedSiteEntity?.plants?.find((plant) => plant.id === selectedPlant),
        [selectedPlant, selectedSiteEntity?.plants]
    );

    const handleSiteChange = (option: SelectOption) => {
        setSelectedSite(Number(option.value));

        setSampleUpdate((prevState) => ({
            ...prevState,
            location: null,
        }));
    };

    const handlePlantChange = (option: SelectOption) => {
        setSelectedPlant(Number(option.value));
        setSampleUpdate((prevState) => ({
            ...prevState,
            location: null,
            locationId: null,
        }));
    };

    const handleLocationChange = (option: SelectOption) => {
        const location = selectedPlantEntity?.locations?.find((location) => location.id === Number(option.value));

        setSampleUpdate((prevState) => ({
            ...prevState,
            locationId: location.id,
            location: { ...location, plant: selectedPlantEntity },
        }));
    };

    const handleDateTimeChange = (newValue) => {
        setSampleUpdate((prevState) => ({ ...prevState, sampledTimestampUtc: newValue }));
    };

    const handleOperatorChange = (selectedOption: SelectOption) => {
        setSampleUpdate((prevState) => ({ ...prevState, operator: selectedOption?.value }));
    };

    const handleSamplerChange = (selectedOption: SelectOption) => {
        setSampleUpdate((prevState) => ({ ...prevState, sampler: selectedOption?.value }));
    };

    const handleLabTechChange = (selectedOption: SelectOption) => {
        setSampleUpdate((prevState) => ({ ...prevState, labTech: selectedOption?.value }));
    };
    const handleNotesChange = (event) => {
        setSampleUpdate((prevState) => ({ ...prevState, notes: event.target.value }));
    };

    const handleDepthToChange = (event) => {
        const depthTo = Number(event.target.value);
        if (isValidNumber(depthTo)) {
            setSampleUpdate((prevState) => ({ ...prevState, depthTo }));
        }
    };

    const handleDepthFromChange = (event) => {
        const depthFrom = Number(event.target.value);

        if (isValidNumber(depthFrom)) {
            setSampleUpdate((prevState) => ({ ...prevState, depthFrom }));
        }
    };

    const handleDrillholeChange = (event) => {
        const drillHoleNo = event.target.value;
        setSampleUpdate((prevState) => ({ ...prevState, drillHoleNo }));
    };

    const handleTestTypeChange = (option) => {
        const testTypeId = option.value;
        setSampleUpdate((prevState) => ({ ...prevState, testTypeId }));
    };

    const handleCompositeTypeChange = (option) => {
        const compositeTypeId = option.value;
        setSampleUpdate((prevState) => ({ ...prevState, compositeTypeId }));
    };

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container spacing={2}>
                    {showTitle && (
                        <Grid item xs={12}>
                            <Typography variant="h6">Details</Typography>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Site"
                            entities={sites}
                            isLoadingEntities={isLoadingSites}
                            selectedValues={selectedSite}
                            handleChange={handleSiteChange}
                            //Disabled if not manager role
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Plant"
                            entities={sites?.find((site) => site.id === selectedSite)?.plants}
                            isLoadingEntities={isLoadingSites}
                            selectedValues={selectedPlant}
                            handleChange={handlePlantChange}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Location"
                            entities={
                                sites
                                    ?.find((site) => site.id === selectedSite)
                                    ?.plants?.find((plant) => plant.id === selectedPlant)?.locations
                            }
                            isLoadingEntities={isLoadingSites}
                            selectedValues={sampleUpdate.locationId}
                            handleChange={handleLocationChange}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Specific Location"
                            entities={sampleUpdate?.location?.specificLocations}
                            isLoadingEntities={isLoadingSites}
                            selected={selectedSpecificLocation}
                            handleChange={setSelectedSpecificLocation}
                            filter={(specificLocation) => specificLocation.locationId === sampleUpdate.locationId}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimeField
                                    label="Date/Time"
                                    onChange={handleDateTimeChange}
                                    value={displayLocalTimestamp(sampleUpdate.sampledTimestampUtc)}
                                    disabled={isReadOnly || disabled}
                                    //loading={false}
                                    sx={{
                                        "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
                                            WebkitTextFillColor: "rgba(83, 83, 83, 1)",
                                        },
                                        "& .MuiInputBase-input": {
                                            fontWeight: 400,
                                        },
                                        "& .MuiInputLabel-root": {
                                            WebkitTextFillColor: "rgba(83, 83, 83, 1)",
                                            fontWeight: 400,
                                        },
                                    }}
                                    size="small"
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <UserSelect
                            userGroup={UserGroup.QualityControl}
                            userRole={UserRole.Operator}
                            handleUserChange={handleOperatorChange}
                            selectedUser={sampleUpdate.operator}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <UserSelect
                            userGroup={UserGroup.QualityControl}
                            userRole={UserRole.Sampler}
                            handleUserChange={handleSamplerChange}
                            selectedUser={sampleUpdate.sampler}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <UserSelect
                            userGroup={UserGroup.QualityControl}
                            userRole={UserRole.LabTech}
                            handleUserChange={handleLabTechChange}
                            selectedUser={sampleUpdate.labTech}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Test Type"
                            entities={testTypes}
                            isLoadingEntities={isLoadingTestTypes}
                            selectedValues={sampleUpdate.testTypeId}
                            handleChange={handleTestTypeChange}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Composite Type"
                            entities={compositeTypes}
                            isLoadingEntities={isLoadingCompositeTypes}
                            selectedValues={sampleUpdate.compositeTypeId}
                            handleChange={handleCompositeTypeChange}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    {/** Drillhole No, Depth From, Depth To are for core samples */}
                    <Grid item xs={3}>
                        <VividTextField
                            label="Drillhole No."
                            onChange={handleDrillholeChange}
                            value={sampleUpdate?.drillHoleNo || ""}
                            placeholder="DHXXXX-YY"
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Depth From"
                            type="number"
                            value={sampleUpdate?.depthFrom || ""}
                            onChange={handleDepthFromChange}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Depth To"
                            type="number"
                            value={sampleUpdate?.depthTo || ""}
                            onChange={handleDepthToChange}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Notes"
                            multiline
                            value={sampleUpdate?.notes || ""}
                            onChange={handleNotesChange}
                            fullWidth
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export const SampleDetailsSkeleton = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h6">
                <Skeleton />
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
        <Grid item xs={3}>
            <Skeleton />
        </Grid>
    </Grid>
);
